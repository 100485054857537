<script>
import SPFGrid from '@/components/List/SPFGrid.vue';
import BillableDataService from "@/services/BillableDataService";

export default {
  name: 'BillableList',
  extends: SPFGrid,
  props: {
    apiSlug: { default: 'billables' },
    vueSlug: { default: 'billable' },
  },
  data() {
    return {
      dataService: BillableDataService,
      fieldConfig: BillableDataService.getGridConfig(),
    }
  }
}
</script>
