<template>
  <div>
    <BillableList />
  </div>
</template>

<script>
import BillableList from "@/components/BillableList";

export default {
  name: "BillablesView",
  components: { BillableList },
}
</script>
