export default {
    methods: {
        getInputType(cfg, scope = 'edit') {
            if (! cfg) {
                return false;
            }

            if (cfg[scope] !== undefined) {
                if (cfg[scope] === false) return null;
            }

            if (cfg.resolve) {
                return "select";
            }
            if (cfg.type) {
                return cfg.type;
            }
            return "text";
        }
    }
}
